import '../index.css';
import conseilTLS from '../image/conseilTLS.webp'

import calendrier from '../image/icones/calendrier.webp'

import projet  from '../image/icones/projet.webp'
import daffaires  from '../image/icones/plan-daffaires.webp'
import brand  from '../image/icones/brand-image.webp'
import marche from '../image/icones/etude-de-marche.webp'
import client from '../image/icones/client.webp'
import carriere from '../image/icones/carriere.webp'


function TLS_Conseil() {
  return (
    <div id="conseil" className="container-fluid text-center slideanim">
    <h2 className="txtServ">TLS-CONSEIL</h2>
    <div className="row text-center slideanime">
        <div className="container descriptionService">
            <h3>
            Parlez nous de vos rêves, nous vous accompagnons pour 
              les réaliser. <br></br>
              Rendre vos rêve réalité, est notre priorité
            </h3>
        </div>
        <div className="row">
            <div className="thumbnail col-sm-4">
            <img src={conseilTLS} alt="Design et création" />
            </div>
            <div className="thumbnail servicedigital col-sm-8 " >

               
                <div id="myCarousel2" className="carousel slide" data-ride="carousel">
                    {/* <!-- Indicators --> */}
                    <ol class="carousel-indicators">
                              <li data-target="#myCarousel2" data-slide-to="0" class="active"></li>
                              <li data-target="#myCarousel2" data-slide-to="1"></li>
                              <li data-target="#myCarousel2" data-slide-to="2"></li>
                              <li data-target="#myCarousel2" data-slide-to="3"></li>
                              <li data-target="#myCarousel2" data-slide-to="4"></li>
                              <li data-target="#myCarousel2" data-slide-to="5"></li>
                              <li data-target="#myCarousel2" data-slide-to="6"></li>
                              <li data-target="#myCarousel2" data-slide-to="7"></li>
                            </ol>
                
                    {/* <!-- Wrapper for slides --> */}
                    <div class="carousel-inner">
                              <div class="item text-center active">
                                <a>
                                    <img src={projet} alt="montage des projets" />
                                    <h3>Accompagnement et conseil au montage des projets</h3>
                                </a>
                                
                              </div>
                        
                              <div class="item text-center">
                                <a>
                                    <img src={daffaires} alt="Création des business plan" />
                                    <h3> Création des business plan</h3>
                                </a>
                              </div>
                            
                              <div class="item text-center">
                                <a>
                                    <img src={brand} alt=" Création d’identité visuelle " />
                                    <h3>Création d’identité visuelle </h3>
                                </a>
                              </div>
                              <div class="item text-center">
                                <a>
                                    <img src={marche} alt=" Études de marchés" />
                                    <h3> Études de marchés</h3>
                                </a>
                              </div>
                              
                              <div class="item text-center">
                                <a>
                                    <img src={marche} alt=" Études de marchés" />
                                    <h3> Conseils bancaires par des professionnels du domaine</h3>
                                </a>
                              </div>

                              <div class="item text-center">
                                <a>
                                    <img src={client} alt=" Assistance commerciale" />
                                    <h3> Assistance commerciale </h3>
                                </a>
                              </div>
                              <div class="item text-center">
                                <a>
                                    <img src={carriere} alt="  Formation professionnelle" />
                                    <h3>  Formations professionnelles</h3>
                                </a>
                              </div>
                              <div class="item text-center">
                                <a>
                                    <img src={calendrier} alt="  Communication événementielle" />
                                    <h3>  Communication événementielle</h3>
                                </a>
                              </div>
                            </div>
                
                    {/* <!-- Left and right controls --> */}
                    <a className="left carousel-control" href="#myCarousel2" data-slide="prev">
                      <span className="glyphicon glyphicon-chevron-left"></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="right carousel-control" href="#myCarousel2" data-slide="next">
                      <span className="glyphicon glyphicon-chevron-right"></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
            </div>
           
        </div>
      
    </div>
</div>
  
  );
}

export default TLS_Conseil;
