import '../index.css';
import logoblan from '../image/logoblan.webp'
import facebook from '../image/facebookblanc.webp'
import instagram from '../image/instagramblanc.webp'
import linkdIn from '../image/linkdeInblanc.webp'
import local  from '../image/local.webp';
import tele  from '../image/tele.webp';
import email from '../image/email.webp';


function Footer() {
  return (
    <footer className="footer-section">
        <div className="container">
            <div className="footer-cta pt-5 pb-5">
                <div className="row my-4 py-4">
                    <div className="col-xl-4 col-md-4 mb-30 txt-single-cta">
                        <div className="single-cta">
                            <div className="cta-text">
                                <img src={local} width={30} height={30} />
                                <span>&emsp;8 Av. Mohamed V, Salé</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30 txt-single-cta">
                        <div className="single-cta">
                            <div className="cta-text">
                                <img src={tele} width={30} height={30} />
                                <span>&emsp;05 30 06 50 24</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30 txt-single-cta">
                        <div className="single-cta">
                            <div className="cta-text">
                                <img src={email} width={30} height={30} />
                                <span>&emsp;contact@tradeline-solutions.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-50 pb-5 text-center ">
                        <div className="footer-widget text-center">
                            <div className="footer-logo">
                                <a href="index.html"><img src={logoblan} className="img-fluid" alt="logo" /></a>
                            </div>
                            <div className="footer-text" >
                                &emsp;&emsp;Trade-Line Solutions est un cabinet fondé par une jeune équipe ayant cumulée une expérience marché dans plusieurs domaines. <br></br>
                                &emsp;&emsp;Le courtage l'immobilier, le conseil et accompagnement, ainsi que la création et gestion Digitale, sont les trois volets que le cabinet propose, pour ses collaborateurs et ses clients.
                            </div>
                            <div className="footer-social-icon text-lg-center my-3">
                                <span>Follow us</span>
                                <a className="mx-3" target={'blank1'} href="https://web.facebook.com/Trade.Line.Solutions"><img src={facebook} width={40} height={40} alt="" /></a>
                                <a className="mx-3" target={'blank2'} href="https://www.instagram.com/tls.solutions/"><img src={instagram} width={40} height={40} alt="" /></a>
                                <a className="mx-3" target={'blank3'} href="https://www.linkedin.com/company/trade-line-solutions/"><img src={linkdIn} width={40} height={40} alt="" /></a>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 mb-30 text-center">
                        <div className="footer-widget text-center">
                            <div className="footer-widget-heading">
                                <h2 style={{color:'#fff'}}>Trouvez-Nous</h2>
                            </div>
                            {/* <ul>
                                <li><a href="#header">Acceuil</a></li>
                                <li><a href="#immobilier">Immobilier</a></li>
                                <li><a href="#digital">Digital</a></li>
                                <li><a href="#conseil">Conseil</a></li>
                                <li><a href="#offre">Packs</a></li>
                                <li><a href="#contact">Contact</a></li>
                            </ul> */}
                            <div>
                                <iframe scrolling="no" 
                                        marginheight="0" 
                                        marginwidth="0" 
                                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=34.054200%20-6.794450+(Trade%20Line%20Solutions)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" 
                                        width="100%" 
                                        height="300" frameborder="0">
                                    <a href="https://www.gps.ie/sport-gps/">fitness tracker</a>
                                    </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div className="copyright-area">
        <div className="container">
            <div className="row">
                <div className="col-xl col-lg text-center text-lg-center">
                    <div className="copyright-text">
                        <p>Copyright &copy; 2021, All Right Reserved <a target="blanck" href="https://www.linkedin.com/in/ayoub-choukri-b43997160/">TRADE LINE SOLUTIONS</a></p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </footer>

  );
}

export default Footer;
