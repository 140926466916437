/* eslint-disable jsx-a11y/alt-text */
import conseil from '../image/conseilTLS.webp';
import degital from '../image/degitalTLS.webp';
import  immobilier  from '../image/houseTLS.webp';

import '../index.css';

function Service() {
  return (
    <div className="container-fluid bg-grey text-center">
        <div className='row slideanime'>
            <div className='col-sm-4 secteur'>
              <a href="#digital">
                  <img src={ degital }  width={150} height={100} />
                  <h4>TLS-DIGITAL</h4>
              </a>  
            </div>
            <div className="col-sm-4 secteur">
              <a href="#immobilier">
                    <span><img src={ immobilier }  width={150} height={100} /></span>
                    <h4>TLS-IMMOBILIER</h4>
              </a>
            </div>
            <div className="col-sm-4 secteur">
            <a href="#conseil">
                    <span><img src={ conseil }  width={150} height={100} /></span>
                    <h4>TLS-CONSEIL</h4>
            </a>
            </div>
        </div>
    </div>

  );
}

export default Service;
