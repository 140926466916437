import '../index.css';
import turboimmo from '../image/turboimmo.webp'
import assistanceachat from '../image/assistanceachat.webp'
import assistancevente from '../image/assistancevente.webp'
import gestionlocative from '../image/gestionlocative.webp'
import conseilbancaire from '../image/conseilbancaire.webp'
import houseTLS from '../image/houseTLS.webp'
import conseiltechnique from '../image/conseiltechnique.webp';


function TLS_Immo() {
  return (
      

            <div id="immobilier" class="container-fluid  text-center slideanim">
        <h2 class="txtServ">TLS-IMMOBILIER</h2>
        <div class="container descriptionService">
            <h3>
            Particulier, professionnel ou personne morale, Nous vous proposons un accompagnement pour la réalisation de vos transactions immobilière au Maroc : l’achat, la vente, 
            aussi bien qu’à la gestion locative. <br></br>
            Nous vous assurant : 
            </h3>
        </div>
        <div class="row" >
            <div class="thumbnail  col-sm-8" >
                <div id="myCarousel1" class="carousel slide" data-ride="carousel">
                    {/* <!-- Indicators --> */}
                    <ol class="carousel-indicators">
                      <li data-target="#myCarousel1" data-slide-to="0" class="active"></li>
                      <li data-target="#myCarousel1" data-slide-to="1"></li>
                      <li data-target="#myCarousel1" data-slide-to="2"></li>
                      <li data-target="#myCarousel1" data-slide-to="3"></li>
                      <li data-target="#myCarousel1" data-slide-to="4"></li>
                      <li data-target="#myCarousel1" data-slide-to="5"></li>
                    </ol>
                
                    {/* <!-- Wrapper for slides --> */}
                    <div class="carousel-inner">
                      <div class="item item-immo text-center active">
                        <a>
                            <img src={turboimmo} alt="" />
                        </a>
                        
                      </div>
                
                      <div class="item item-immo text-center">
                        <a>
                            <img src={assistanceachat} alt="" />
                        </a>
                      </div>
                    
                      <div class="item item-immo text-center">
                        <a>
                            <img src={assistancevente} alt="" />
                        </a>
                      </div>

                      <div class="item item-immo text-center">
                        <a>
                            <img src={gestionlocative} alt="" />
                        </a>
                      </div>

                      <div class="item item-immo text-center">
                        <a>
                        <img src={conseilbancaire} alt="" />
                        </a>
                      </div>

                      <div class="item item-immo text-center">
                        <a href="">
                            <img src={conseiltechnique} alt="" />
                        </a>
                      </div>

                    
                    </div>
                
                    {/* <!-- Left and right controls --> */}
                    <a class="left carousel-control" href="#myCarousel1" data-slide="prev">
                      <span class="glyphicon glyphicon-chevron-left"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control" href="#myCarousel1" data-slide="next">
                      <span class="glyphicon glyphicon-chevron-right"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
            </div>
            <div class="thumbnail col-sm-4">
                <img src={ houseTLS } alt="" />
            </div>
        </div>
        <div>
          <a className='btnC p-3' target={"blank"} href='https://www.tradeline-solutions.com/TLS-immo/'> Nos Offres </a>
        </div>
        
    </div>
  );
}

export default TLS_Immo;
