import video from '../image/video1.mp4';
import '../index.css';

function Header() {
  return (
    //   Container (Acceuil) 
    <div id="header" class="header">  
    <video id="myVideo" autoPlay muted loop>
       <source src={ video } type="video/mp4" />
    </video>
        {/* <video id="myVideo" src={video} width="750" height="500" autoplay muted loop></video> */}
    </div>

  );
}

export default Header;
