import '../index.css';

function Packs() {
  return (
    <div id="offre" className="container-fluid">
    <div className="text-center">
        <h2 className="txtServ">Les Packs Professionnels</h2>
    </div>
    <div className="row slideanim">
        <div className="col-sm-4 col-xs">
            <div className="panel panel-default text-center">
                <div className="panel-heading">
                    <h1>Basic</h1>
                </div>
                <div className="panel-body ">
                    <p>&#10004; <strong>20</strong> Annonces</p>
                    <p>&#10004; <strong>5</strong> Images Par Annonce</p>
                    <p>&#10004; <strong>1</strong> Annonce en Vedette</p>
                    <p>&#10004; Gestion personnalisée des annonces</p>
                    <p>&#10004; Contact direct avec les clients</p>
                    <p>&#10004; Gestion personnalisée des annonces</p>
                    <p>&#10004; Contact direct avec les clients</p>
                    <p>&#10004; Gestion des prospects</p>
                    <p>&#10004; Gestion des agents</p>
                    <p>&#10004; Suivi des statistiques </p>
                    <p>&#10004; Support technique </p>
                </div>
                <div className="panel-footer">
                    <h3>Sur Devis </h3>
                    <h4>par mois</h4>
                    <button className="btn btn-lg">DEMANDEZ UN DEVIS</button>
                </div>
            </div>
        </div>
        <div className="col-sm-4 col-xs">
            <div className="panel panel-default text-center">
                <div className="panel-heading">
                    <h1>Pro</h1>
                </div>
                <div className="panel-body ">
                    <p>&#10004; <strong>50</strong> Annonces</p>
                    <p>&#10004; <strong>5</strong> Images Par Annonce</p>
                    <p>&#10004; <strong>3</strong> Annonce en Vedette</p>
                    <p>&#10004; Gestion personnalisée des annonces</p>
                    <p>&#10004; Contact direct avec les clients</p>
                    <p>&#10004; Gestion personnalisée des annonces</p>
                    <p>&#10004; Contact direct avec les clients</p>
                    <p>&#10004; Gestion des prospects</p>
                    <p>&#10004; Gestion des agents</p>
                    <p>&#10004; Suivi des statistiques </p>
                    <p>&#10004; Support technique </p>
                </div>
                <div className="panel-footer">
                    <h3>Sur Devis </h3>
                    <h4>par mois</h4>
                    <button className="btn btn-lg">DEMANDEZ UN DEVIS</button>
                </div>
            </div>
        </div>
        <div className="col-sm-4 col-xs">
            <div className="panel panel-default text-center">
                <div className="panel-heading">
                    <h1>Premium</h1>
                </div>
                <div className="panel-body ">
                    <p>&#10004; Annonces illimitées</p>
                    <p>&#10004; Bannière en page d'accueil</p>
                    <p>&#10004; Pages personnalisées sur le Site</p>
                    <p>&#10004; <strong>15</strong> Images Par Annonce</p>
                    <p>&#10004; <strong>3</strong> Annonce en Vedette</p>
                    <p>&#10004; Gestion personnalisée des annonces</p>
                    <p>&#10004; Contact direct avec les clients</p>
                    <p>&#10004; Contact direct avec les clients</p>
                    <p>&#10004; Gestion des prospects et des agents</p>
                    <p>&#10004; Suivi des statistiques </p>
                    <p>&#10004; Support technique </p>
                </div>
                <div className="panel-footer">
                    <h3>Sur Devis </h3>
                    <h4>par mois</h4>
                    <button className="btn btn-lg">DEMANDEZ UN DEVIS</button>
                </div>
            </div>
        </div>
    </div>

</div>
  
  );
}

export default Packs;
