import React from 'react';


import '../index.css';
import degitalTLS from '../image/degitalTLS.webp'
import espaceDigital  from '../image/icones/espaceDigital.webp'
import AnimationSocial from '../image/icones/AnimationSocial.webp'
import strategie from '../image/icones/strategie.webp'
import Ads from '../image/icones/Ads.webp'
import siteweb from '../image/icones/siteweb2.webp'
import Maquette from '../image/icones/Maquette.webp'
import conseilFormation from '../image/icones/conseilFormation.webp'


import { BrowserRouter as Router,Route ,Redirect,Switch } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

// import Service from '../service';

function TLS_Digital() {
  return (
       <Router>
          <div id="digital" className="container-fluid text-center slideanim">
              <h2 className="txtServ">TLS-DIGITAL</h2>
              <div className="row text-center slideanime">
                  <div className="container descriptionService">
                      <h3>
                      Osez la technologie, et Suivez la tendance pour développer vos chiffres d’affaires, 
                      et conquérir de nouveaux marchés grâce à votre présence digitale.<br></br>
                      Nous vous assurons :
                      </h3>
                  </div>
                  <div className="row">
                      <div className="thumbnail col-sm-4">
                      <img src={degitalTLS} alt="Design et création" />
                      </div>
                      <div className="thumbnail servicedigital col-sm-8 " >

                        
                          <div id="myCarousel" className="carousel slide" data-ride="carousel">
                              {/* <!-- Indicators --> */}
                              <ol className="carousel-indicators">
                                <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                <li data-target="#myCarousel" data-slide-to="1"></li>
                                <li data-target="#myCarousel" data-slide-to="2"></li>
                                <li data-target="#myCarousel" data-slide-to="3"></li>
                                <li data-target="#myCarousel" data-slide-to="4"></li>
                                <li data-target="#myCarousel" data-slide-to="5"></li>
                                <li data-target="#myCarousel" data-slide-to="6"></li>
                              </ol>
                          
                              {/* <!-- Wrapper for slides --> */}
                              <div className="carousel-inner">
                                <div className="item text-center active">                         
                                    <a>
                                          <img src={espaceDigital} alt="La création des espaces digitaux" />
                                          <h3>La création des espaces digitaux</h3>
                                    </a>
                                </div>
                          
                                <div className="item text-center">
                                    <a>
                                        <img src={AnimationSocial} alt="Animation et création du contenu social" />
                                        <h3>Animation et création du contenu social</h3>
                                    </a>
                                </div>
                              
                                <div className="item text-center">
                                    <a>
                                        <img src={strategie} alt="Élaboration des stratégies digitales" />
                                        <h3>Élaboration des stratégies digitales</h3>
                                    </a>
                                </div>

                                <div className="item text-center">
                                    <a>
                                        <img src={Ads} alt="Création et gestion des campagnes publicitaires digitales" />
                                        <h3>Création et gestion des campagnes publicitaires digitales</h3>
                                    </a>
                                </div>

                                <div className="item text-center">
                                    <a>
                                        <img src={siteweb} alt=" Création des sites-web et APK" />
                                        <h3> Création des sites-web et APK</h3>
                                    </a>
                                </div>

                                <div className="item text-center">
                                    <a>
                                        <img src={Maquette} alt="Création des maquettes intelligentes " />
                                        <h3>Création des maquettes intelligentes </h3>
                                    </a>
                                </div>

                                <div className="item text-center">
                                    <a>
                                        <img src={conseilFormation} alt="Conseil et formation à la vente en ligne " />
                                        <h3>Conseil et formation à la vente en ligne </h3>
                                    </a>
                                </div>
                              </div>
                          
                              {/* <!-- Left and right controls --> */}
                              <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                                <span className="glyphicon glyphicon-chevron-left"></span>
                                <span className="sr-only">Previous</span>
                              </a>
                              <a className="right carousel-control" href="#myCarousel" data-slide="next">
                                <span className="glyphicon glyphicon-chevron-right"></span>
                                <span className="sr-only">Next</span>
                              </a>
                            </div>
                      </div>
                    
                  </div>
                
              </div>
          </div>
          <Switch>
          <Route path="/Design">
            
            <Design />
          </Route>
          
        </Switch>
       </Router>
      
        
  );
}
 function Design () {
  return <h1>Design</h1>;
}

// function Sites() {
//   return <h2>About</h2>;
// }

// function Événementiel() {
//   return <h2>Users</h2>;
// }

export default TLS_Digital;
