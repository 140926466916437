import '../index.css';


function Contact() {
  return (
    <div id="contact" className="container-fluid bg-grey">
        <h2  className="txtServ text-center">CONTACT</h2>
        <div className="row">
            <div className="col-sm-5 slideanim">
                <p>Contactez-nous et nous vous répondrons dans les 24 heures. </p>
                <p><span className="glyphicon glyphicon-map-marker"></span> SALE, MAROC</p>
                <p><span className="glyphicon glyphicon-phone"></span> 05 30 06 50 24</p>
                <p><span className="glyphicon glyphicon-envelope"></span> contact@tradeline-solutions.com</p>
            </div>
            <div className="col-sm-7 slideanim">
                <div className="row">
                    <div className="col-sm-6 form-group">
                        <input className="form-control" id="name" name="name" placeholder="Name" type="text" required />
                    </div>
                    <div className="col-sm-6 form-group">
                        <input className="form-control" id="email" name="email" placeholder="Email" type="email" required />
                    </div>
                </div>
                <textarea className="form-control" id="comments" name="comments" placeholder="Comment" rows="5"></textarea>
                <div className="row">
                    <div className="col-sm-12 form-group">
                        <button className="btn btn-default pull-right" type="submit">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  );
}

export default Contact;
