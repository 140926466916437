import '../index.css';
import imgTop from '../image/Ups.webp'

function Top() {
  return (
    <div class="Toop">
       
            <a href="#myPage"><img src={imgTop} width={50} height={50} alt="" /> </a>
        
    </div>
  
  );
}

export default Top;
