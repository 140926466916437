import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import Navbar from './component/Navbar';
import Header from './component/Header';
import Services from './component/Services';
import Top from './component/Top';
import TLS_Digital from './component/TLS-Digital';
import TLS_Immo from './component/TLS-Immo';
import TLS_Conseil from './component/TLS-Conseil';
import Packs from './component/Packs';
import Contact from './component/Contact';
import ContactUs from './component/ContactUs';
import Footer from './component/Footer';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Navbar />
    <Header />
    <Services />
    <Top />
    <TLS_Digital />
    <TLS_Immo />
    <TLS_Conseil/>
    <Packs />
    <Contact />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
