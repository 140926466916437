import logo from '../image/logo.webp';
import '../index.css';

function Navbar() {
  return (
    
    <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
            <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>                        
      </button>
                <a className="navbar-brand" href="#myPage">
                    <img src={logo} className="logoTLS" alt="TradeLine Solutions" />
                    </a>
            </div>
            <div className="collapse navbar-collapse" id="myNavbar">
                <ul className="nav navbar-nav navbar-right">
                    <li><a href="#header">ACCEUIL</a></li>
                    <li><a href="#digital">DIGITAL</a></li>
                    <li><a href="#immobilier">IMMOBILIER</a></li>
                    <li><a href="#conseil">CONSEIL</a></li>
                    <li><a href="#offre">PACKS</a></li>
                    <li><a href="#contact">CONTACT</a></li>
                    
                </ul>
            </div>
        </div>
    </nav>
  );
}

export default Navbar;
